@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300&display=swap);
@import url('./Component/assets/css/common.css');
@import url('./Component/assets/css/layout.css');
@import url('./Component/assets/css/auth.css');
@import url('./Component/assets/css/dashboard.css');

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF;
  font-size: 12px;
  color: #212529;
  margin: 0;
  padding: 0;
}

/* ::-webkit-scrollbar {
  width: 0;
  background: transparent;
} */
.gradient-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: #fecc4e;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 20px;
}
.okk{
  width: 100px;
}
.loginForm input{
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid #ffff;
  background: transparent !important;
  color: #ffff;
  height: 40px;
}
.loginForm .form-control{
  border:none !important;  
  background: transparent !important;
  border-bottom: 1px solid #ffff !important;
  color: #ffffff;
}
.ant-input-password-icon.anticon {
  color: #ffffff;
  cursor: pointer;
  transition: all .3s;
}
.transparent-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px !important;
  text-align: center;
  border-radius: 30px;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: 1px solid;
}

.filter-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  /* padding: 6px 20px !important; */
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 10px;
  background: #fecc4e;
  border-color: #fecc4e;
  cursor: pointer;
  /* outline: none;
  border: none; */
  /* height: 35px; */
}
.admin-button {
  color: #ffffff;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 5px;
  background: #349CFF;
  border-color: #4ba6fb;
  cursor: pointer;
  /* outline: none;
  border: none; */
  /* height: 35px; */
}
.admin-button:hover {
  color: #212529;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 5px;
  background: #4ba6fb;
  border-color: #4ba6fb;
  cursor: pointer;
  /* outline: none;
  border: none; */
  /* height: 35px; */
}
.admin-button2 {
  color: #ffffff;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 5px 7px !important;
  text-align: center;
  border-radius: 5px;
  background: #4ba6fb;
  border-color: #4ba6fb;
  cursor: pointer;
  /* outline: none;
  border: none; */
  /* height: 35px; */
}
.admin-button2:hover {
  color: #212529;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 5px 7px !important;
  text-align: center;
  border-radius: 5px;
  background: #4ba6fb;
  border-color: #4ba6fb;
  cursor: pointer;
  /* outline: none;
  border: none; */
  /* height: 35px; */
}
.admin-button1 {
  color: #ffffff;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 5px;
  background: rgb(245, 111, 75);
  cursor: pointer;
  border-color: rgb(245, 111, 75);
  /* outline: none;
  border: none; */
  /* height: 35px; */
}

.clear-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: 1px solid #fecc4e;
}

.scale-icon {
  max-width: 18px;
  height: auto;
}
.scale-icon1 {
  max-width: 14px;
  height: auto;
}

.ant-modal-title {
  color: #212529;
}

.ant-modal-content {
  background: #FFFFFF;
  color: #212529;
}

.ant-select {
  color: #212529 !important;
  outline: none !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: none;
}

.ant-select-selector {
  min-height: 35px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 0.5rem;
}

.status_active {
  font-size: 12px;
  font-weight: 700;
  color: #0E6F6B;
  background-color: #C9F7F5;
  border-color: #C9F7F5;
  border-radius: 8px;
  padding: 4px 10px 4px;
  cursor: pointer;
}

.status_inactive {
  font-size: 12px;
  font-weight: 700;
  color: #AA1C2B;
  background-color: #FFE2E5;
  border-color: #FFE2E5;
  border-radius: 8px;
  padding: 4px 10px 4px;
  cursor: pointer;
}

.status_inactive:hover {
  font-weight: 700;
  color: #AA1C2B;
  background-color: #FFE2E5;
  border-color: #FFE2E5;
  border-radius: 8px;
  cursor: pointer;
}

.edit-icon {
  max-width: 19px;
  height: auto;
  font-size: 25px;
  color: #1559a2;
}
#components-dropdown-demo-arrow-center .ant-btn {
  margin-right: 8px;
  margin-bottom: 8px;
}
.ant-row-rtl #components-dropdown-demo-arrow-center .ant-btn {
  margin-right: 0;
  margin-bottom: 8px;
  margin-left: 8px;
}
.faq-icon {
  width: 50px;
  font-size: 25px;
  padding-left: 15px;
  color: #1559a2;
}

input[readonly] {
  background-color: #f7f7f7;
  border: unset;
}

input[readonly]:hover,
input[readonly]:focus {
  border: unset;
  box-shadow: unset;
}

.ant-menu-submenu-selected {
  fill: #1559A2;
}

.clear-button1 {
  z-index: 99;
}

.table tbody td {
  font-weight: 500;
  max-width: fit-content;
  min-width: auto;
  font-size: 12px !important;
}

.table thead th {
  font-size: 12px !important;
}

.ant-pagination-options {
  display: none;
}

.ant-form-item-control-input-content .wrapper {
  z-index: 999 !important;
}

.cmyNjn>.wrapper {

  /* height: 36px !important; */
  border: 1px solid #d4d4d4 !important;
  border-radius: 10px !important;
  min-height: 36px !important;
}

.cmyNjn>.wrapper:active {
  box-shadow: rgb(32 33 36 / 0%) 0px 1px 6px 0px !important;
}

.cmyNjn>.wrapper:hover {
  box-shadow: rgb(32 33 36 / 0%) 0px 1px 6px 0px !important;
}

.fDbOPw {
  min-height: 36px !important;
}

div#testid_erro {
  color: red;
}

.appli_view_label {
  font-size: 14px;
  font-weight: 500;
}

.appli_view {
  margin-top: 15px;
}

.job-post-add {
  position: unset;
  display: block;
  margin: auto;
  margin-right: 2%;
}

/* .tox.tox-silver-sink.tox-tinymce-aux {
  display: none;
} */

.ex-export span.anticon.anticon-file-excel {
  font-size: 30px;
  color: #109d59;
  display: inline-block;
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.ex-export {
  position: absolute;
  right: 5%;
  bottom: 5px;
}

.btn-exl {
  background: none;
  border: 0px;
}

label {
  cursor: pointer;
  /* Style as you please, it will become the visible UI component. */
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.view-attachment {
  width: 20px;
  font-size: 25px;
  margin: 0 4px;
  padding-top: 0;
}

.bg_gray {
  background: #f7f7f7;
  height: 200px;
  border: none;
}

.doc_icon {
  width: 40px;
}

.doc_icon-w {
  width: 25px;
}

.table_first_col {
  width: 70px !important;
}

.table_action_col {
  width: 150px !important;
}

.table_status_col {
  width: 150px !important;
}

.jop_btn {
  background-color: #1559a2;
  padding: 5px 15px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  border-radius: 5px;
}

.jop_btn svg {
  width: 20px;
  margin-left: 2px;
  height: 22px;
  vertical-align: middle;
}

.char_count {
  float: right;
  font-size: 13px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.d-none {
  display: none;
}
.c-bl{
  color: #1890ff;
}
.frameSet{
  padding: 20px 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

/*  */
.success-button {
  color: #ffffff;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 10px;
  background: #48D7B9;
  cursor: pointer;
  border-color: #48D7B9;
  /* outline: none; */
  /* border: 1px solid #48D7B9; */
  /* height: 35px; */
}
.delete-button {
  color: #fdfeff;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: #ff1515;
  border-color: #ff1515;
  cursor: pointer;
  /* outline: none;
  border: none; */
  /* height: 35px; */
}
.delete-button2 {
  color: #fdfeff;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 10px;
  background: #ff1515;
  border-color: #ff1515;
  cursor: pointer;
  /* outline: none;
  border: none; */
  /* height: 35px; */
}
.delete-button5 {
  color: #fdfeff;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 10px;
  background: #ff1515;
  border-color: #ff1515;
  cursor: pointer;
  /* outline: none;
  border: none; */
  /* height: 35px; */
}
.delete-button6 {
  color: #fdfeff;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 10px;
  background: #5999AF;
  border-color: #5999AF;
  cursor: pointer;
  /* outline: none;
  border: none; */
  /* height: 35px; */
}
.delete-button5 svg {
  color: #fdfeff;
  
}
.ant-descriptions-bordered .ant-descriptions-item-content, .ant-descriptions-bordered .ant-descriptions-item-label {
  padding: 3px 5px;
  border-right: 1px solid #f0f0f0;
}
.gradient-button1 {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px !important;
  text-align: center;
  /* border-radius: 10px; */
  /* background: #fecc4e; */
  background-image: linear-gradient(to right, #F26FC9, #8772EF);
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 20px;
}

/* .form-select {
  width: 100%;
  height: 30px;
} */

.userDetailTable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.userDetailTablet {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  background-color: #40189d;
  width: 100%;
}
.userDetailTablet2 {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
  background-color: #40189d;
  /* width: 100%; */
  color: #fff;
}

.companyName{
  font-size: 14px !important;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
  
}

.invotb tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.05) ;
}

.userDetailTable  tr {
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
}
.userDetailTable  td {
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
}

.coustomNevc {
  color: #009ef7 !important;
  cursor: pointer;
}
.coustomtrue {
  color: #00f742 !important;
  cursor: pointer;
}
.coustomfalse {
  color: #f70000 !important;
  cursor: pointer;
}

.ant-upload-select {
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid #d9d9d9;
}

.uploadimgcon{
  position: relative;
  width: 150px;
}
.uploadimgcon img{
  margin-left: 30px;
}
.uploadimgcon1{
  position: absolute;
  top: -18px;
  left: 1px;
}
.uploadimgcon2{
  position: absolute;
  top: -18px;
  left: 120px;
}

.profilebtntopbar{
  background: #FF5722; 
  height: 62px;
  border: none;
  color: white;
}

.topheadericon2{
  background: #FF5722;
  height: 62px;
  padding-left: 10px;
  padding-right: 10px;
}
.topheadericon{
  background: #FF5722;
}

.code-box {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 0 16px;
  background-color: #ffffff;
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-radius: 6px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  padding: 15px;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-2 {
  font-size: calc(0.5rem + .9vw)!important;
}
.py-20 {
  padding: 2.7rem !important;
  /* padding-bottom: 5rem !important; */
}

.chatBoxHeight {
  height: 300px !important;
  /* overflow-y: scroll;
  position: relative; */
}

.scroll-y {
  overflow-y: scroll;
  position: relative;
  height: 300px !important;
}

.proadDImgContainer {
  position: relative;
  width: 100px;
}

.proadDImgBtn {
  position: absolute;
  top: -17px;
  right: -28px;
}

.dataTables_paginate.paging_simple_numbers.category {
  float: right;
}

.dataTables_wrapper .dataTables_paginate {
  padding: 10px 0;
}

.dataTables_paginate.paging_simple_numbers.category .previous a {
  /* color: #40189D; */
  color: var(--bs-pagination-color) !important;
}
.dataTables_paginate.paging_simple_numbers.category .next a {
  /* color: #40189D; */
  color: var(--bs-pagination-color) !important;
}
.dataTables_paginate.paging_simple_numbers.category li:nth-child(1) {
  background: transparent !important;
  color: var(--bs-pagination-color) !important;
}

.dataTables_paginate.paging_simple_numbers.category li{
  border: 0 !important;
  padding: 7px 16px;
  border: 0px solid #40189D !important;
  /* background: rgba(64, 24, 157, 0.3) !important; */
  border-radius: 1rem;
  color: var(--bs-pagination-color) !important;
  font-size: 16px;
  margin: 0;
  display: inline-block;
}
.bolgBox img{
max-width: 100%;
}

.dataTables_paginate.paging_simple_numbers.category li:hover{
     background: rgba(18, 18, 209, 0.1) !important;
}
/* .dataTables_paginate.paging_simple_numbers.category li:nth-child(1){

  background: transparent !important;
  
 
} */
.dataTables_paginate.paging_simple_numbers.category li:last-child{
 
  background: transparent !important;
  color: var(--bs-pagination-color) !important;
}
.fw-semibold .text-gray-600 tr td {
  color: var(--bs-pagination-color) !important;
}

.dataTables_paginate.paging_simple_numbers.category li.selected{
  color: #fff !important;
  background: #009ef7 !important;
}


.rankrole table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.rankrole td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.rankrole tr:nth-child(even) {
  background-color: #dddddd;
}

.loadingbox{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loadinggg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.dts{
  width: 20px;
  height: 20px;
  border: 1px solid black;
}

.symbol.symbol-100px.symbol-fixed > img {
  width: 100px;
  height: 100px;
  max-width: none;
}

.sidebar_menus .ant-menu-item {
  /* border-bottom: 1px solid white; */
  margin-bottom: 1px !important;
  height: 28px;
  font-size: 15px;
}
.dashboardBoxShad{
  min-width: 200px;
  overflow: auto;
}
.downloadInvoice{
  color: #0062cc;
}

.invoiceBrack {
  /* page-break-before: always; */
  page-break-after: always;
}

#kt_app_content{
  padding: 10px 25px;
}
.dashboardBoxShadvs{
  min-width: 400px;
}
.sidebar_menus .ant-menu-submenu {
  /* border-bottom: 1px solid white; */
  margin-bottom: 1px !important;
  /* height: 26px; */
}

.ant-menu-inline>.ant-menu-item, .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical-left>.ant-menu-item, .ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical-right>.ant-menu-item, .ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical>.ant-menu-item, .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 22px !important;
  /* line-height: 11px; */
}

.ant-menu-inline>.ant-menu-item, .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical-left>.ant-menu-item, .ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical-right>.ant-menu-item, .ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical>.ant-menu-item, .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  /* height: 40px; */
  line-height: 25px;
}
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon, .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon, .ant-menu.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed>.ant-menu-item .anticon, .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
}

.table_first_colmm {
  min-width: 150px;
}

.app-content .tbr {
  overflow: auto;

}
.profile_ulv {
  padding: 9px;
  background-color: #fff;
  /* border-radius: 10px; */
  border: 1px solid #ddd;
  box-shadow: 0px 0px 22px 0px #dcdcdc;
  margin-bottom: 2px;
  /* margin-right: 10px; */
  height: 68px;
}

.ldbox:hover .profile_ulv {
  background: #0062cc;
  color: #ffffff;
  cursor: pointer;
}